import React from 'react'

function FifthCompany() {
  return (
    <>
  {/*?xml version="1.0" encoding="UTF-8"?*/}
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 337.87 383.24"
  >
    <path d="m.97,236.05c-1.59-16.7-1.18-33.43.93-49.04,6.54-48.38,30.2-93.86,64.68-128.24,9.84-9.82,20.57-18.74,32.04-26.6C124.22,14.62,153.88,3.15,184.9.45c18.94-1.65,80.06-.35,71.9,33.11-2.95,12.09-5.94,23.1-9.7,34.98-12.96,40.94-38.88,74.77-69.42,104.37-18.88,18.3-37.77,36.6-56.24,55.3-20.44,20.69-35.03,45.05-43.69,72.91-2.56,8.25-5.26,17.37-8.09,25.54-1.42,4.12-2.74,10.67-7.37,12.41-9.98,3.75-21.02-7.01-26.85-13.8C14.83,301.27,4.08,268.7.97,236.05Z" />
    <path d="m337.87,170.52c-2.48,57.7-21.54,101.91-54.01,140.13-30.37,35.74-72.87,63.47-119.68,70.92-18.94,3.01-37.06,1.63-55.8-1.46-13.11-2.16-24.43-9.14-25.7-23.41-1.76-19.85,5.88-38.94,13.22-56.96,6.87-16.88,15.21-33.53,26.43-47.97,30.07-38.69,71.33-67,102.16-105.07,19.22-23.74,32.62-50.51,39.68-80.33.64-2.71,1.15-6.2,3.1-8.34,1.79-1.97,4.75-1.84,7.18-1.73,9.06.4,18.88,1.37,26.98,5.76,1.04.56,2.04,1.18,3.01,1.86,3.66,2.58,5.82,6.72,7.84,10.6,2.23,4.29,4.39,8.62,6.43,13,4.07,8.72,7.7,17.66,10.59,26.84,2.21,7,3.99,14.14,5.22,21.39,2.33,13.73,2.72,27.8,3.35,34.77Z" />
  </svg>
</>

  )
}

export default FifthCompany