
export const PHONE ="600"

export const TABLETS ="768"

export const MIDLAPTOP ="992"

export const LARGLAPTOP ="1200"





