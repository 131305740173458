import React from 'react'
import NavBar2 from '../../layout/NavBar2'
import IMAGE from '../../assets/LinkImg.png'
function UnderConst() {
  return (
    <div>
    
        <NavBar2/>
        <img src={IMAGE} alt='' style={{width:"80vw",height:"80vh",margin:"auto"}}/>
    </div>
  );
}

export default UnderConst