import React from 'react'

function SecondCompany() {
  return (
    <>
  {/*?xml version="1.0" encoding="UTF-8"?*/}
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250.22 724.67"
    // className='svg-first-company'

  >
    <path
      d="m137.33,213.47c-4.54-.22-7.16-4.51-5.76-8.27,3.36-9.01,5.79-17.56,6.3-27.42.48-9.23,1.49-17.7-3.58-25.38-.68-1.03-4.8-4.81-6.24-5.2-7.39-2.01-13.81,2.64-17.8,8.45-3.59,5.22-5.77,11.28-6.45,17.67-.8,7.48,1.86,15,3.49,22.22,1.5,6.65.17,9.87-5.27,12.75-5.34,2.83-13.23-.32-18.43-2.13-16.4-5.69-28.55-21.29-30.82-38.39-1.24-9.33,1.08-18.76,3.94-27.72,10.15-31.86,27.1-61.04,43.93-89.92,8.09-13.9,13.53-24.87,26-37.13,2.83-2.78,6.64-5.8,10.21-7.53,4.21-2.04,21.03-8.47,13.15-3.83-3.28,1.93-5.98,4.49-10.47,9.06-6.89,7.02-10.47,13.66-12.64,22.34-1.44,5.78-2.77,12.6-2.3,18.89.31,4.09,2.2,6.53,6.47,6.66,9.11.28,16.02-7.27,24.97-7.5,4.22-.11,8.09,1.95,10.91,5,8.1,8.75,13.16,19.24,15.86,30.44,3.27,13.54,6.13,28.6,8.54,42.09,2.56,14.28,5.24,34,2.3,43.36-5.49,17.49-18.99,34.56-37.44,38.95-5.81,1.38-12.55,2.83-18.89,2.53Z"
      fill="#fff"
    />
    <polygon
      points="46.3 371.65 206.5 478.97 191.13 303.15 46.3 371.65"
      fill="#fff"
    />
    <polygon
      points="191.32 490.45 46.1 391.91 30.07 551.57 191.32 490.45"
      fill="#fff"
    />
    <polygon
      points="32.69 566.03 221.28 642.28 209.37 504.41 32.69 566.03"
      fill="#fff"
    />
    <polygon
      points="119.35 644.27 27.24 581.01 17.85 646.86 119.35 644.27"
      fill="#fff"
    />
    <polygon
      points="250.22 658.96 250.22 704.24 232.86 704.24 232.86 722.62 204.94 722.62 204.94 704.24 37.45 704.24 37.45 724.67 9.54 724.67 9.54 704.24 0 704.24 0 658.96 250.22 658.96"
      fill="#fff"
    />
    <polygon
      points="188.35 286.79 50.74 354.13 61.96 220.84 181.11 217.77 188.35 286.79"
      fill="#fff"
    />
  </svg>
</>

  )
}

export default SecondCompany