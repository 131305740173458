import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tooltip/dist/react-tooltip.css'
import AppRoutes from './Routes';


const App = () => {

  return (
    <AppRoutes />
    )
}

export default App