import React from 'react'

function ThirdCompany() {
  return (
    <>
  {/*?xml version="1.0" encoding="UTF-8"?*/}
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 564.39 529.56"
  >
    <g>
      <path d="m18.94,199.72c-3.93.84-8.26-1.59-10.53-5.05-14.35-21.86-7.07-64.19-2.01-88.3,5.16-24.59,17.11-48.44,36.81-64.03,30.49-24.13,72.83-24.54,111.7-23.86,58.29,1.03,116.59,2.06,174.88,3.09,30.39.54,64.32-.26,85.99-21.58,2.22,7.36,9.47,12.91,17.16,13.13s15.24-4.91,17.87-12.14c2.73,12.11,20.69,11.35,30.21,19.32,6.03,5.05,8.12,13.29,12.39,19.89,11.53,17.83,39.33,24.86,41.98,45.93-4.58,2.4-9.17,4.8-13.75,7.2-1.2.63-2.45,1.3-3.16,2.45-1.96,3.15,1.23,6.9,4.03,9.33,19.93,17.33,34.24,41.02,40.31,66.73,1.56,6.6,2.55,13.7.14,20.04-3.21,8.43-11.78,13.67-20.42,16.28-13.82,4.18-28.59,3.19-42.96,4.54-14.37,1.35-29.71,5.87-38.06,17.64-3.12,4.4-6.03,10.23-11.41,10.61-2.38.17-4.68-.86-6.77-2.01-9.68-5.34-17.81-14.5-19.45-25.43-2.84-18.93,14.44-35.66,32.78-41.13s37.99-3.34,57.03-5.26c-7.38-14.91-15.95-30.97-31.25-37.49-13.73-5.85-29.4-2.5-44.07.28-100.49,19.04-204.02,6.52-306.17,11.55-12.28.61-25.21,1.68-35.36,8.63-13.18,9.02-18.49,25.59-22.93,40.93-6.27,21.65-12.55,43.3-18.82,64.95-3.36,11.6-11.96,20.39-16.01,4.83-3.44-13.21-2.06-29.74-1.16-43.23,1.83-27.28,8.49-54.25,19.75-79.18,5.61-12.41,12.88-24.91,24.7-31.68,9.09-5.2,19.85-6.42,30.29-7.27,61.14-4.94,122.6-.66,183.93-1.32s123.8-6.54,180.23-30.57c-27.71-11.35-58.97-7.01-88.76-3.98-84.96,8.62-170.62,4.85-256,3.19-18.45-.36-37.85-.4-54.07,8.42-18.95,10.31-30.03,30.96-36.09,51.66-6.07,20.7-8.2,42.43-14.92,62.93-1.25,3.8-3,7.94-6.67,9.53-.45.19-.9.34-1.36.44Z" />
      <path
        d="m391.8,409.32c0,1.79-.01,3.59-.04,5.38-.08,5.43-2.46,7.73-6.79,7.02,3.91-2.69,6.42-7.25,6.26-12.36v-.04l-.6-19.41-.19-6.4c.47,1.04.69,2.43.79,4.27.04.71.07,1.42.1,2.13.33,6.46.48,12.94.47,19.41Z"
        fill="#fff"
      />
    </g>
    <path d="m391.8,409.32c0,1.79-.01,3.59-.04,5.38-.08,5.43-2.46,7.73-6.79,7.02,3.91-2.69,6.42-7.25,6.26-12.36v-.04h.57Z" />
    <path d="m530.98,465.22h-7.25l-.59-19.41h2.68c8.04,0,14.56-6.51,14.56-14.55v-43.92c0-8.03-6.52-14.55-14.56-14.55h-4.92l-.9-29.32c-.14-4.38-2.26-8.47-5.76-11.11l-37.34-28.13c-5.54-4.17-13.26-3.82-18.41.82l-32.52,29.4c-2.92,2.64-4.64,6.35-4.76,10.28l-.87,28.06h-20.96l-.92-29.83c-.13-4.38-2.25-8.47-5.75-11.11l-37.34-28.13c-5.54-4.17-13.26-3.83-18.41.82l-32.52,29.4c-2.92,2.64-4.64,6.35-4.76,10.28l-.88,28.57h-16.88l.91-29.87c-.13-4.39-2.25-8.47-5.75-11.11l-37.35-28.13c-5.53-4.17-13.25-3.83-18.4.82l-32.52,29.4c-2.92,2.63-4.64,6.35-4.76,10.28l.18,28.61h-20.11l-.89-28.98c-.14-4.38-2.25-8.47-5.76-11.11l-37.34-28.13c-5.54-4.17-13.26-3.82-18.4.82l-32.53,29.4c-2.92,2.64-4.64,6.35-4.76,10.28l-.86,27.72h-17.76c-8.03,0-14.55,6.52-14.55,14.55v43.92c0,8.04,6.52,14.55,14.55,14.55h15.5l-.6,19.41h-14.95c-7.16,0-12.97,5.8-12.97,12.96v38.41c0,7.16,5.81,12.97,12.97,12.97h485.21c7.17,0,12.97-5.81,12.97-12.97v-38.41c0-7.16-5.8-12.96-12.97-12.96Zm-346.18,0h-17.84l-.59-19.41h18.31v2.04s.12,17.36.12,17.36h0Zm111.13,0h-16.8l.53-17.37.06-2.04h16.81l-.6,19.41Zm121.55,0h-14.69c0,1.79-.01,3.59-.04,5.38-.08,5.43-2.46,7.73-6.79,7.02,14.77,8.92,12.64-.6,6.26-12.36v-.04s-.6-19.41-.6-19.41l1.98-.23c.47,1.04-1.48-3.74-1.38-1.9.04.71.07,1.42.1,2.13h15.76l-.6,19.41Z" />
    <path d="m391.33,389.91h-.7l-.19-6.4c.47,1.04.69,2.43.79,4.27.04.71.07,1.42.1,2.13Z" />
  </svg>
</>

  )
}

export default ThirdCompany